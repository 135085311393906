export const educationData = [
    
    {
        id: 1,
        institution: 'Tulane University (U.S.A, New Orleans)',
        course: 'Master of Business Admininstartion (M.B.A)',
        startYear: '2010',
        endYear: '2012 '
    },
    {
        id: 2,
        institution: 'University Politecnica de Catalunya (Spain, Barcelona)',
        course: 'Bachelor of Telecommunication Engineer + master',
        startYear: '1999',
        endYear: '2005'
    },

    
]