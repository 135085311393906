import resume from '../assets/pdf/JordiFiguerasResume.pdf'

export const headerData = {
    name: 'Jordi Figueras',
    title: "Entrepreneur, Web3 & blockchain enthusiast, software engineer, Tulane MBA",
    desciption:'I am a Software engineer  with experience with  web3 and  Blockchain.  ',
    desciption2:"I have almost 10 years of professional experience working in many different industries academia, GIS, artificial vision, gaming, and blockchain.",
    desciption3:" In addition to my full stack skills, I am a strong communicator and good leader.",
    image: 'https://media-exp1.licdn.com/dms/image/C4E03AQEoFBkTQhZhZQ/profile-displayphoto-shrink_800_800/0/1516304745341?e=1666224000&v=beta&t=XNmypyH2vnLROaJ9coNVYjm-rDsASUxoW2qQPJQPvmU',
    resumePdf: resume
}
