export const experienceData = [
    {
        id: 1,
        company: 'Mintknight',
        jobtitle: 'Core software engineer',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Insanitea Kombucha',
        jobtitle: 'Founder - Owner',
        startYear: '2017',
        endYear: '2021'
    },
    {
        id: 3,
        company: 'Search influence',
        jobtitle: 'Software engineer - Ruby on Rails',
        startYear: '2016',
        endYear: '2017'
    },
    {
        id: 4,
        company: 'Tenbo Air',
        jobtitle: 'Software engineer / Product owner ',
        startYear: '2014',
        endYear: '2016'
    },
]