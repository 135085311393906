import one from '../assets/svg/projects/one.svg'
//import two from '../assets/svg/projects/two.svg'
//import three from '../assets/svg/projects/three.svg'
//import four from '../assets/svg/projects/four.svg'
//import five from '../assets/svg/projects/five.svg'
//import six from '../assets/svg/projects/six.svg'
//import seven from '../assets/svg/projects/seven.svg'
//import eight from '../assets/svg/projects/eight.svg'
import howtouse from '../assets/png/Howtouse.png'


export const projectsData = [
    {
        id: 1,
        projectName: 'NFT API',
        projectDesc: 'This project aims to give a suit of tools for companies to create, and manage NFT',
        tags: ['NodeJs','Etherum', 'solidity','React'],
        code: 'https://github.com/MintKnight/mintknight',
        demo: 'https://api.mintknight.com/api-docs/',
        image: one
    },
    {
        id: 2,
        projectName: 'How to use abortion pill',
        projectDesc: 'This project was freelanced for an ONG that supports the freedom of abortion worldwide',
        tags: ['Ruby on Rails', 'Bootstrap'],
        code: 'https://www.howtouseabortionpill.org/',
        demo: 'https://www.howtouseabortionpill.org/',
        image: howtouse
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/